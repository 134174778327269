<template>
  <div class="charging-map">
    <GmapMap
      ref="mapRef"
      :center="{ lat: location.lat, lng: location.lng }"
      :zoom="8.5"
      map-type-id="roadmap"
      style="width: 100%; height: 500px"
      @bounds_changed="mapUpdated"
      @idle="$emit('idle')"
      @zoom_changed="handleZoom"
      :options="{
        mapTypeControl: false,
        streetViewControl: false,
        scaleControl: false,
        fullscreenControl: false,
        scrollwheel: true,
        minZoom: 5,
        restriction: {
          latLngBounds: {
            east: -50,
            west: -170,
            north: 90,
            south: 20,
          },
        },
      }"
    >
      <GmapCircle
        :center="mapCenter"
        :radius="radius * 1609.34"
        :options="{ fillOpacity: 0.35, strokeWeight: 0, fillColor: '#18ff00' }"
      />
      <GmapMarker
        :key="9999 + idx"
        v-for="(loc, idx) in dcFastStations.stations"
        :position="{ lat: loc.coordinates.lat, lng: loc.coordinates.lng }"
        :clickable="true"
        :draggable="false"
        :title="loc.name"
        @click="toggleInfoWindow(loc)"
      />
      <GmapMarker
        :key="index"
        v-for="(loc, index) in level2Stations.stations"
        :position="{ lat: loc.coordinates.lat, lng: loc.coordinates.lng }"
        :clickable="true"
        :draggable="false"
        :title="loc.name"
        @click="toggleInfoWindow(loc)"
      />
      <GmapInfoWindow
        :opened="infoWinOpen"
        :options="infoWinOpt"
        :position="infoWinPos"
        @closeclick="infoWinOpen = false"
      >
        <div v-html="infoContent"></div>
      </GmapInfoWindow>
    </GmapMap>
  </div>
</template>
<script>
import common from "Mixins/common";

export default {
  props: {
    radius: {
      type: Number,
      default: 50
    },
    showDCFast: {
      type: Boolean,
      default: true
    },
    showLevel2: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      infoWinPos: { lat: 0, lng: 0 },
      infoWinOpen: false,
      infoContent: "",
      infoWinOpt: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      mapCenter: {
        lat: 0,
        lng: 0
      },
    };
  },
  methods: {
    handleZoom($event) {
      this.$emit('zoomed', $event)
    },
    mapUpdated() {
      this.mapCenter = this.$refs?.mapRef.$mapObject?.center
      this.$emit('change', {
        lat: this.mapCenter.lat(),
        lng: this.mapCenter.lng()
      })
    },
    
    toggleInfoWindow(marker) {
      this.infoWinPos = { lat: marker.coordinates.lat, lng: marker.coordinates.lng };
      this.infoContent = this.getInfoWindowContent(marker);

      this.infoWinOpen = true;
    },
    getInfoWindowContent(marker) {
      return (
        `<div>
          <strong>${marker.name}</strong>
          <div>${marker.address}</div>
          <div>${marker.city_state_zip}</div>` +
        (marker.level2_charger_count > 0
          ? `<div>Level 2: ${marker.level2_charger_count} ${this.pluralize(
              marker.level2_charger_count,
              "charger"
            )}</div>`
          : "") +
        (marker.dc_fast_charger_count > 0
          ? `<div>DC Fast (${this.dcFastDescription}): ${
              marker.dc_fast_charger_count
            } ${this.pluralize(marker.dc_fast_charger_count, "charger")}</div>`
          : "") +
        `</div>`
      );
    },
    /*getIconUrl(location) {
      if (location.ev_dc_fast_num > 0)
        return "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
      else return "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
    },*/
  },
  computed: {
    location() {
      return this.$store.state.chargingStationInfo.location
    }, 
    dcFastStations() {
      return {
        count: this.$store.getters.filterChargingLocations('dc_fast').length,
        stations: this.showDCFast ? this.$store.getters.filterChargingLocations('dc_fast'): []
      }
    },
    level2Stations() {
      return {
        count: this.$store.getters.filterChargingLocations('level2').length,
        stations: this.showLevel2 ? this.$store.getters.filterChargingLocations('level2'): []
      }
    },
    chargingLocations() { 
      return this.$store.getters.filterChargingLocations()
    }
  },
  mixins: [common],
};
</script>
<style lang="scss" scoped>
.charging-map {
  color: $secondary-font-color-dark;
}
</style>