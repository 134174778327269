var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"charging-map"},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"500px"},attrs:{"center":{ lat: _vm.location.lat, lng: _vm.location.lng },"zoom":8.5,"map-type-id":"roadmap","options":{
      mapTypeControl: false,
      streetViewControl: false,
      scaleControl: false,
      fullscreenControl: false,
      scrollwheel: true,
      minZoom: 5,
      restriction: {
        latLngBounds: {
          east: -50,
          west: -170,
          north: 90,
          south: 20,
        },
      },
    }},on:{"bounds_changed":_vm.mapUpdated,"idle":function($event){return _vm.$emit('idle')},"zoom_changed":_vm.handleZoom}},[_c('GmapCircle',{attrs:{"center":_vm.mapCenter,"radius":_vm.radius * 1609.34,"options":{ fillOpacity: 0.35, strokeWeight: 0, fillColor: '#18ff00' }}}),_vm._l((_vm.dcFastStations.stations),function(loc,idx){return _c('GmapMarker',{key:9999 + idx,attrs:{"position":{ lat: loc.coordinates.lat, lng: loc.coordinates.lng },"clickable":true,"draggable":false,"title":loc.name},on:{"click":function($event){return _vm.toggleInfoWindow(loc)}}})}),_vm._l((_vm.level2Stations.stations),function(loc,index){return _c('GmapMarker',{key:index,attrs:{"position":{ lat: loc.coordinates.lat, lng: loc.coordinates.lng },"clickable":true,"draggable":false,"title":loc.name},on:{"click":function($event){return _vm.toggleInfoWindow(loc)}}})}),_c('GmapInfoWindow',{attrs:{"opened":_vm.infoWinOpen,"options":_vm.infoWinOpt,"position":_vm.infoWinPos},on:{"closeclick":function($event){_vm.infoWinOpen = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoContent)}})])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }